@import url(https://fonts.googleapis.com/css?family=Pacifico&display=swap);
.App, .Start, .End {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}

h1 {
	font-family: 'Pacifico', cursive;
	font-size: 3em;
	/*color: #810e05;*/	
	background: -webkit-linear-gradient(#d02718, #810e05);
	-webkit-background-clip: text;
	background-clip: text;
  -webkit-text-fill-color: transparent;
}

p {
  font-size: 1.5em;
}

.End strong {
  color: green;
}

button {
  font-size: 1em;
  font-weight: bold;
  width: 150px;
  height: 50px;
  box-shadow:inset 0px 1px 0px 0px #8a2a21;
  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #c62d1f), color-stop(1, #f24437));
  background:-webkit-linear-gradient(top, #c62d1f 5%, #f24437 100%);
  background:-webkit-gradient(linear, left top, left bottom, color-stop(5%, #c62d1f), to(#f24437));
  background:linear-gradient(to bottom, #c62d1f 5%, #f24437 100%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#c62d1f', endColorstr='#f24437',GradientType=0);
  background-color:#c62d1f;
  border-radius:3px;
  border:1px solid #d02718;
  display:inline-block;
  cursor:pointer;
  color:#ffffff;
  font-family:Arial;
  font-size:13px;
  padding:6px 24px;
  text-decoration:none;
  text-shadow:0px 1px 0px #810e05;
}

button:hover {
  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #f24437), color-stop(1, #c62d1f));
  background:-webkit-linear-gradient(top, #f24437 5%, #c62d1f 100%);
  background:-webkit-gradient(linear, left top, left bottom, color-stop(5%, #f24437), to(#c62d1f));
  background:linear-gradient(to bottom, #f24437 5%, #c62d1f 100%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#f24437', endColorstr='#c62d1f',GradientType=0);
  background-color:#f24437;
}

button:active {
  position:relative;
  top:1px;
}

.Start button {
  font-size: 1.7em;
  width: 160px;
  height: 60px;
}

.App button {
  font-size: 1.5em;
  margin: 5px;
}

.End button {
  font-size: 1.2em;
  width: 160px;
  height: 60px;
}

.Menor, .Maior {
	box-shadow:inset 0px 1px 0px 0px #54a3f7;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #007dc1), color-stop(1, #0061a7));
	background:-webkit-linear-gradient(top, #007dc1 5%, #0061a7 100%);
	background:-webkit-gradient(linear, left top, left bottom, color-stop(5%, #007dc1), to(#0061a7));
	background:linear-gradient(to bottom, #007dc1 5%, #0061a7 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#007dc1', endColorstr='#0061a7',GradientType=0);
	background-color:#007dc1;
	border-radius:3px;
	border:1px solid #124d77;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:13px;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #154682;
}

.Menor:hover, .Maior:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #0061a7), color-stop(1, #007dc1));
	background:-webkit-linear-gradient(top, #0061a7 5%, #007dc1 100%);
	background:-webkit-gradient(linear, left top, left bottom, color-stop(5%, #0061a7), to(#007dc1));
	background:linear-gradient(to bottom, #0061a7 5%, #007dc1 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#0061a7', endColorstr='#007dc1',GradientType=0);
	background-color:#0061a7;
}

.Menor:active, .Maior:active {
	position:relative;
	top:1px;
}

.Acertou {
	box-shadow:inset 0px 1px 0px 0px #3dc21b;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #02b030), color-stop(1, #26cf1d));
	background:-webkit-linear-gradient(top, #02b030 5%, #26cf1d 100%);
	background:-webkit-gradient(linear, left top, left bottom, color-stop(5%, #02b030), to(#26cf1d));
	background:linear-gradient(to bottom, #02b030 5%, #26cf1d 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#02b030', endColorstr='#26cf1d',GradientType=0);
	background-color:#02b030;
	border-radius:3px;
	border:1px solid #18ab29;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:13px;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #2f6627;
}

.Acertou:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #26cf1d), color-stop(1, #02b030));
	background:-webkit-linear-gradient(top, #26cf1d 5%, #02b030 100%);
	background:-webkit-gradient(linear, left top, left bottom, color-stop(5%, #26cf1d), to(#02b030));
	background:linear-gradient(to bottom, #26cf1d 5%, #02b030 100%);
	background-color:#26cf1d;
}

.Acertou:active {
	position:relative;
	top:1px;
}

